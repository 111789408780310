@import '../../theme.scss';

.request-page {
    background: $primary-color;
    color: #FFF;
    width: 100%;
    height: 100vh;
    overflow: scroll;

    .ads-img {
        width: 100%;
    }

    .right-col {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        position: relative;
    }

    // .right-col, .left-col, .back-btn, .plan-select {
    //     animation: contentShow 0.3s ease-out;
    // }
    .ads-img {
        height: initial !important;
    }

    .primary-btn {
        text-transform: uppercase;
    }

    .select-item {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 18px;
        background: $second-bg;
        border-radius: 10px;
        margin-bottom: 15px;
        padding-left: 15px;
        cursor: pointer;

        .order {
            border-radius: 50%;
            color: #FFF;
            background-color: $third-bg;
        }

        &:nth-child(even) {
            margin-right: 0;
        }

        &.active {
            background-color: $green-color;

            .selected {
                display: flex;
            }

            .order {
                font-weight: bold;
                color: $green-color;
                background-color: #FFF;
            }
        }

        .selected {
            position: absolute;
            width: 22px;
            height: 22px;
            right: 0;
            top: 0;
            border-top-right-radius: 10px;
            background: $green-color;
            border-bottom-left-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;

            .svg-icon {
                fill: white;
                margin-left: 5px;
            }
        }
    }

    .item-container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .bubble {
        box-shadow: 0 4px 30px rgba(42, 26, 127, 0.08);
        padding: 0 27px;
        font-size: 14px;
        line-height: 22px;
    }

    .schedule {
        background: #F6F5F9;
        border-radius: 10px;
        height: 167px;
    }

    .coding-class {
        box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.09);
        padding: 24px 30px 30px;
        background: white;

        .sub-header {
            text-align: center;
            border-bottom: 1px solid rgba(95, 70, 193, 0.1);
            padding-bottom: 22px;

            h2 {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 21px;
                text-transform: uppercase;
            }

            h3 {
                font-size: 48px;
                line-height: 50px;
                color: #712ABC;
                display: flex;
                align-items: baseline;

                span {
                    display: block;
                    font-size: 16px;
                    margin-left: 7px;
                    opacity: 0.4;
                    font-weight: 500;

                    &.ignore {
                        font-size: 28px;
                        text-decoration: line-through;
                        margin-right: 7px;
                    }
                }
            }
        }

        .sub-body {
            padding-top: 24px;

            h4 {
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 16px;
            }

            .sub-item {
                margin-bottom: 17px;
                display: flex;
                align-items: flex-start;

                .svg-icon {
                    fill: #712ABC;
                    margin: 6px 9px 0 0;
                }

                p {
                    font-weight: 500;
                    margin: 0;
                    text-align: left;
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            .btn-link {
                height: 55px;
            }
        }

        &.private {
            .sub-body {
                .btn-link {
                    background: rgba(22, 211, 143, 0.08);
                    color: $green-color;
                }
            }
        }

        &.sibiling {
            .sub-header {
                h3 {
                    color: #2986F8;
                }
            }

            .sub-body {
                .sub-item {
                    .svg-icon {
                        fill: #2986F8;
                    }
                }
            }
        }
    }

    .detail-info {
        .sub-container {
            display: flex;
        }

        h3 {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 11px;
        }
    }

    .input-box,
    .select-box {
        color: #FFF;
        margin-bottom: 15px;
        background-color: $second-bg;

        p {
            margin-bottom: 0;
            text-align: left;
        }
    }

    .progress-bar {
        width: 100%;
        height: 4px;
        background: #F6F5F9;
        border-radius: 5px;

        .current-progress {
            height: 4px;
            border-radius: 5px;
            background: #5F46C1;
        }
    }

    .sub-section {
        .drop-content {
            margin-top: 10px;
            max-height: 255px;
            overflow-y: scroll;
            background-color: $second-bg;

            .select-btn {
                width: 100%;
                margin-bottom: 0;
                border-bottom: solid 1px $second-bg;

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .multi-select {
            .dropdown-heading {
                height: 55px;
            }

            color: $black-color;

            .select-item {
                height: 40px;
                width: 100%;
                display: flex;
                align-items: center;
            }

        }

        .sub-btn-area {
            display: flex;

            .primary-btn {
                min-width: 100px;
                margin-bottom: 15px;
                background-color: $yellow-color;

                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }

        .warning {
            color: #330D63;
            white-space: nowrap;
            font-size: 14px;
            line-height: 24px;
            text-align: left;
            margin-bottom: 23px;
        }
    }

    .check-area {
        margin-bottom: 30px;

        .check-label {
            margin-left: 10px;

            a {
                color: $green-color;
            }
        }
    }

    .flag-select__btn {
        padding: 0;
        margin: 0;
        outline: none;

        .flag-select__option {
            padding: 0;
            margin: 0;
        }
    }

    .next-btn {
        margin-top: 30px;
        justify-content: space-between;

        .back-btn {
            cursor: pointer;
            color: $yellow-color;
            font-weight: bold;
            margin-bottom: 10px;
            width: fit-content;

            .svg-icon {
                margin-right: 5px;
            }
        }

        .continue-btn {
            height: 55px;
            width: 170px;
            color: #FFF;
            font-size: 15px;
            font-weight: 700;
            background-color: $yellow-color;
            border-radius: 5px;
        }
    }
}

@media screen and (min-width: 992px) {
    .request-page {
        margin-top: -87px;
        padding-top: 15px;

        .main-content {
            padding-top: 50px;
        }

        h1 {
            font-size: 40px;
            line-height: 55px;
            margin-bottom: 25px;
        }

        .greating {
            font-size: 18px;
        }

        p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 18px;
        }

        .primary-btn {
            // min-width: 300px;
            // max-width: 620px;
            height: 50px;
        }

        .select-item {
            width: calc(50% - 10px);
            height: 80px;
            margin-right: 20px;

            .order {
                width: 60px;
                height: 60px;
                font-size: 24px;
            }
        }

        .level-item {
            height: 70px;
        }

        .bubble {
            width: 50%;
            bottom: 0;
            right: 0;
            height: 92px;
            position: absolute;
        }

        .schedule {
            width: 429px;
        }

        .left-col {
            height: calc(100vh - 100px);
        }

        .detail-info {
            max-width: 520px;

            .btn-link {
                height: 60px;
            }

            .sub-container {
                justify-content: space-between;

                .input-box {
                    width: 100%;
                }
            }
        }

        .sub-section {

            .sub-btn-area {
                .continue {
                    max-width: 300px;
                    flex: 1;
                }

                .skip {
                    width: 121px;
                    margin-left: 15px;
                }
            }
        }

        .input-box,
        .select-box {
            height: 55px;
        }

        .progress-bar {
            max-width: 378px;
            margin-bottom: 29px;
        }

        .sub-container {
            .select-item {
                margin-right: 0;
                width: calc((100% - 30px) / 3);
            }
        }

        .email-container {
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width: 991px) {
    .request-page {
        margin-top: -70px;
        padding-top: 11px;

        .right-col {
            padding-bottom: 80px;
        }

        .logo-area {
            display: flex;
            justify-content: center;
        }

        .main-content {
            padding-top: 44px;
        }

        h1 {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 9px;
            text-align: center;
        }

        .greating {
            font-size: 15px;
            text-align: center;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 22px;
        }

        .primary-btn {
            height: 50px;
        }

        .ads-img {
            max-width: 177px;
            margin: 0 auto 35px auto;
        }

        .select-item {
            width: 100%;
            height: 55px;

            .order {
                width: 35px;
                height: 35px;
                font-size: 18px;
            }
        }

        .bubble {
            height: 97px;
        }

        .schedule {
            width: 335px;
            margin-left: auto;
            margin-right: auto;
        }

        .coding-class {
            margin-bottom: 45px;
        }

        .detail-info {
            .btn-link {
                height: 55px;
            }

            .sub-container {
                flex-direction: column;
            }
        }

        .input-box,
        .select-box {
            height: 55px;
        }

        .sub-section {
            .sub-btn-area {
                flex-direction: column;
            }
        }

        .left-col {
            flex-direction: column;
        }

        .sub-container {
            .select-item {
                width: 100%;
                margin-right: 0;
            }
        }

        .next-btn {
            position: fixed;
            bottom: 0;
            left: 15px;
            right: 15px;
            background-color: $primary-color;
            padding: 15px 0;
        }
    }
}

@keyframes contentShow {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
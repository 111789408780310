@import '../../theme.scss';

.footer-component {
    .main-footer {
        background-color: $primary-color;
        height: 55px;
        .footer-text {
            font-size: 12px;
            color: #FFF;
        }
    }
}

@media screen and (min-width: 992px) {
    .footer-component {
        background-color: $primary-color;
    }
}

@media screen and (max-width: 991px) {
    .footer-component {
        margin-bottom: 170px;
        .phone-call-area {
            position: fixed;
            bottom: 0px;
            background: #FFF;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            z-index: 10;

            .request-btn {
                background-color: #DA4167;
                height: 55px;
                border-radius: 10px;
                color: #FFF;
                font-size: 15px;
                font-weight: 700;
            }
            .call-btn {
                background-color: $green-color;
                height: 55px;
                border-radius: 10px;
                color: #FFF;
                font-size: 15px;
                font-weight: 700;
            }
        }
    }
}
@import '../../../theme.scss';


.universities-component {
    color: #FFF;
    h1 {
        font-weight: 800;
    }
}

@media screen and (min-width: 992px) {
    .universities-component {
        padding: 100px 0px;
        background: url('../../../Assets/Home/University/university_bg.png') no-repeat;
        background-position: center bottom;
        background-size: 100% 100%;
        h1 {
            font-size: 40px;
            line-height: 55px;
            margin-bottom: 40px;
        }        
    }
}

@media screen and (max-width: 991px) {
    .universities-component {
        padding: 70px 0px;
        background: url('../../../Assets/Home/University/university_bg_mobile.png') no-repeat;
        background-position: center bottom;
        background-size: 100% 100%;
        h1 {
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 10px;
            text-align: center;
        }
        .university-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
        }
        
    }
}
@import '../../theme.scss';

.service-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;

            .service-content {
                display: flex;
                margin: auto;
                position: relative;
                width: 100%;
                .close-btn {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-bottom: -28px;
                    z-index: 10;
                    fill: red;                    
                }
                .title {
                    font-size: 22px;
                    font-weight: bold;
                    text-align: center;
                }
                .detail {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    margin-top: 30px;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
        position: relative;
        height: 100%;
    }
    .service-content {
        background-color: $primary-color;
        max-width: 530px;
        height: 554px;
        padding: 30px;
        border-radius: 10px;
        .title {
            color: #FFF;
        }
        .detail {
            color: #FFF;
        }
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        position: absolute;
        bottom: 0;
    }
    .service-content {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        width: 100%;
        max-height: 560px;
        background-color: #FFF;
        padding: 30px 15px;

        .title {
            color: $primary-color;
        }
        .detail {
            color: $primary-color;
            max-height: 500px;
            overflow-y: scroll;
        }
    }
}
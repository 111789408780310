@import '../../theme.scss';

.welcome-page {
    background: $primary-color;
    color: #FFF;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    .show-me-btn {
        height: 55px;
        border-radius: 7px;
        background-color: $yellow-color;
        color: #FFF;
        font-weight: bold;
    }
    .ads-img {
        width: 100%;
    }    
}

@media screen and (min-width: 992px) {
    .welcome-page {
        margin-top: -87px;
        padding-top: 15px;
        .main-content {
            padding-top: 50px;
        }
        h1 {
            font-size: 40px;
            line-height: 55px;
            margin-bottom: 25px;
        }
        .left-col, .right-col {
            height: calc(100vh - 120px);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 991px) {
    .welcome-page {
        margin-top: -70px;
        padding-top: 11px;
        .logo-area {
            display: flex;
            justify-content: center;
        }
        .main-content {
            padding-top: 44px;
        }
        h1 {
            font-size: 24px;
            line-height: 34px;
            margin-top: 30px;
            margin-bottom: 9px;
            text-align: center;
        }
        .description {
            text-align: center;
        }
        
    }
}

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}
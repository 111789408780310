@import '../../../theme.scss';

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(-50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}

.landing1start-component {
    color: #FFF;
    background-color: $primary-color;
    h1 {
        font-weight: 800;
    }
    p {
        font-weight: 400;
        z-index: 11;
    }
    .strong-title {
        color: $yellow-color;
    }   
    .yes-btn {
        background-color: $yellow-color;
        box-shadow: 0px 5px 0px rgba(251, 169, 46, 0.82);
        height: 50px !important;
    }
    .no-btn {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .right-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
}

@media screen and (min-width: 992px) {
    .landing1start-component {
        display: flex;
        align-items: center;
        height: 705px;
        margin-top: -87px;
        // padding-top: 87px;
        background: url('../../../Assets/Home/start_bg.png') no-repeat;
        background-position: center bottom;
        background-size: 100% 100%;
       
        h1 {
            font-size: 40px;
            line-height: 55px;            
        }
        p {
            font-size: 18px;
            line-height: 30px;
        }
        .arrow {
            position: absolute;
            left: -120px;
            top: 185px;
        }
        .box-item {
            width: 170px;
            height: 55px;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 700;
            color: #FFF;
        }
        .start-img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 991px) {
    .landing1start-component {
        // height: 693px;
        margin-top: -70px;
        padding-top: 70px;
        
        text-align: center;
        h1 {
            font-size: 34px;
            line-height: 44px;
            margin-top: 62px;
        }
        p {
            margin-top: 16px;
            font-size: 16px;
            line-height: 24px;
        }
        .arrow {
            display: none;
        }
        .btn-container {
            justify-content: space-between;
            margin-bottom: 30px;
            .box-item {
                width: 160px;
                height: 55px;
                border-radius: 5px;
                font-size: 18px;
                font-weight: 700;
                color: #FFF;
            }
        }
    }
}
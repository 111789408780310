@import '../../theme.scss';

.feedback-component {
    width: 100%;
    color: $black-color;
    border: solid 1px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 8px 21px rgba(3, 12, 30, 0.09);

    .feedback-header {
        height: 230px;
        cursor: pointer;
        overflow: hidden;
        .avatar {
            width: 100%;
            // height: 100%;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }
        .play-icon {
            position: absolute;
            z-index: 10;
            left: calc(50% - 30px);
            top: calc(50% - 165px);
            width: 60px;
        }
    }
    h2 {
        font-size: 20px;
        font-weight: bold;
    }
    .address {
        font-size: 14px;
    }
    .star-area {
        margin: 0px 0px 15px;
    }
    .feedback {
        font-size: 15px;
        // text-align: center;
        line-height: 24px;
        height: 125px;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }
    .dot {
        width: 19px !important;
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 992px) {
    .feedback-component {
        max-width: 370px;
        .reviewer-info {
            padding: 30px 50px;
        }
    }
}

@media screen and (max-width: 991px) {
    .feedback-component {
        min-width: 335px;
        .reviewer-info {
            padding: 20px 40px;
        }
    }
}
@import '../../../theme.scss';


.aboutus-component {
    color: $black-color;
    h1 {
        font-weight: 800;
    }

    p {
        font-size: 16px;
        line-height: 30px;
    }
    .owl-theme {
        
        .owl-stage-outer {
            padding-top: 30px;
            padding-bottom: 30px;
            .owl-stage {
                display: flex;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .aboutus-component {
        padding: 120px 0px;
        h1 {
            font-size: 40px;
            line-height: 55px;
            margin-bottom: 40px;
        }
        .owl-theme {
            .owl-nav {
                position: absolute;
                display: flex;
                justify-content: space-between;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                left: -25px;
                right: -25px;
                pointer-events: none;
                .owl-prev, .owl-next {
                    pointer-events: auto;
                    outline: none;
                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .aboutus-component {
        padding: 70px 0px;
                
        h1 {
            font-size: 34px;
            margin-bottom: 10px;
            line-height: 44px;
        }
        .owl-theme {
            .owl-stage-outer {
                padding-bottom: 30px;
            }
            .owl-nav {
                margin: 0;
                .owl-prev, .owl-next {
                    outline: none;
                    &:hover {
                        background: white;
                    }
                }
                .owl-prev {
                    margin: 0 15px 0 5px;
                }
                .owl-next {
                    margin: 0 5px 0 15px;
                }
            }
        }
    }
}
@import '../../theme.scss';

.home-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;

            .home-content {
                display: flex;
                margin: auto;
                position: relative;
                width: 100%;
                background-color: #FFF;
                color:$black-color;

                .close-btn {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: -30px;
                    fill: white;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon-close {
                        width: 100%;
                        height: 100%;
                        div {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transform: rotate(90deg);
                        }
                    }
                }
                .logo-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .main-row {
                    margin-top: auto;
                    margin-bottom: auto;
                    .sub-description {
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .strong-call {
                        color: $green-color;
                    }
                    .call-back {
                        // border-top: solid 1px $gray-color;
                    }
                    .input-box {
                        height: 55px;
                    }                
                    .request-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #DA4167;
                        border-radius: 5px;
                        color: #FFF;
                        font-size: 15px;
                        font-weight: 700;
                        height: 55px;
                        cursor: pointer;
                    }
                    .helpful {
                        font-size: 15px;
                    }
                    .or {
                        font-size: 15px;
                        color: #302A32;
                        margin-left: auto;
                        margin-right: auto;
                        width: 34px;
                        height: 30px;
                        background-color: #FFF;
                        margin-top: -17px;
                    }
                    .line {
                        border-top: solid 1px $gray-color;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
        position: relative;
        height: 100%;
    }
    .home-content {
        max-width: 1120px;
        // height: 600px;
        padding: 40px 30px;
        border-radius: 10px;

        h1 {
            font-size: 28px;
        }
        .input-box {
            width: 70%;
            border: none;                    
        } 
        .left-area {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .right-area {
            .phone-container {
                .phone {
                    font-size: 34px;
                    font-weight: 700;
                    color: $yellow-color;
                }
            }
            .phone-input-content {
                display: flex;
                border: solid 1px $gray-color;
                border-radius: 10px;            
                .request-btn {
                    width: 210px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        // position: absolute;
        // bottom: 0;
        position: relative;
        height: 100%;
    }
    .home-content {
        border-radius: 10px;

        // width: 100%;
        // max-height: calc(100vh - 50px);
        // overflow-y: scroll;
        padding: 30px 15px;

        h1 {
            font-size: 24px;
            text-align: center;
        }
        
        .right-area {
            .sub-description {
                text-align: center;
            }
            .request-btn {
                margin-top: 30px;
            }
        }
    }
}
@import '../../../theme.scss';


.brand-component {
    .main-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0.4;
    }
}

@media screen and (min-width: 992px) {
    .brand-component {
        padding: 20px 0px;
              
    }
}

@media screen and (max-width: 991px) {
    .brand-component {
        padding: 10px 0px;
        
    }
}
@import '../../../theme.scss';


.services-component {
    background-color: $primary-color;
    color: #FFF;
    h1 {
        font-weight: 800;
    }
    p {
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
    }
    .category-item {
        justify-content: space-between;
        display: flex;
        border-radius: 5px;
        margin-bottom: 20px;
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);

        .logo-container {
            border-radius: 50%;
            background-color: #FFF;
        }
    }
    .category-active {
        background-color: $green-color;
        color: #FFF;
        .item-description {
            font-weight: 700;
        }
    }    
}

@media screen and (min-width: 992px) {
    .services-component {
        padding: 100px 0px;
        h1 {
            font-size: 40px;
            margin-bottom: 40px;
        }
        .category-item {
            height: 80px;
            padding-left: 15px;

            .logo-container {
                width: 60px;
                height: 60px;
            }
            .item-description {
                font-size: 18px;
            }
        }
        .right-area {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

@media screen and (max-width: 991px) {
    .services-component {
        padding: 70px 0px;
                
        h1 {
            font-size: 34px;
            margin-bottom: 30px;
        }

        .category-item {
            height: 55px;
            padding-left: 10px;
            padding-right: 10px;
            
            align-items: center;
            .logo-container {
                width: 35px;
                height: 35px;
                .logo {
                    width: 20px;
                }
            }
            .item-description {
                font-size: 15px;
            }
        }
        .right-brand {
            width: 100%;
        }
        .detail-active {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .detail-inActive {
            display: none;
        }
        
    }
}
@import '../../theme.scss';

.multiSelect-component {
    height: 100%;
    width: 100%;
    color: $black-color;
    position: relative;
    &.focused {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        ::placeholder {
            color: #330D63;
        }
        .last-icon {
            transform: rotate(0);
        }
    }
    .selected-subject-content {
        width: 100%;
        height: 55px;
        border-radius: 10px;
        background-color: $second-bg;
        .subject-list-content {
            width: calc(100% - 30px);
            height: 55px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            overflow-y: scroll;
            .selected-subject {
                cursor: pointer;
                padding: 5px;
                border-radius: 3px;
                background-color: $green-color;
                .subject {
                    color: #FFF;
                    font-size: 15px;
                    white-space: nowrap;
                }
            }
        }
        .arrow-content {
            width: 30px;
            height: 100%;
        }
    }
    .drop-container {
        width: 100%;
        height: 160px;
        background-color: #FFF;
        border-radius: 10px;
        border: solid 1px $gray-color;
        margin-top: 10px;
        padding: 15px;
        .input-area {
            border-bottom: solid 1px $gray-color;
            margin-bottom: 15px;
            .custom-input {
                border: none;
            }
        }
        .subject-list-area {
            height: 110px;
            overflow-y: scroll;
            .item-content {
                margin-bottom: 10px;
                cursor: pointer;
                .check-icon-content {
                    width: 20px;
                    height: 20px;
                    border-radius: 3px;
                    border: solid 1px $gray-color;
                }
                .active-check {
                    background-color: $green-color;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .multiSelect-component {
        .custom-input {
            font-size: 16px;
        }
        .options {
            top: 60px;
        }
    }
}

@media screen and (max-width: 991px) {
    .multiSelect-component {
        .custom-input {
            font-size: 14px;
        }
        .options {
            top: 55px;
        }
    }
}
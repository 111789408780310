@import '../../theme.scss';

.header-component {
    position: fixed;
    z-index: 10;
    color: #fff;
    background: white;
    width: 100%;
    box-shadow: 0px 4px 45px rgba(42, 26, 127, 0.07);

    .header-container {
        height: 100%;
        justify-content: space-between;
    }

    .text-menu {
        margin-right: 39px;
        padding-bottom: 4px;
        // &:last-child {
        //     margin-right: 0;
        // }
        &:hover {
            color: $primary-color;
            padding-top: 2px;
            border-bottom: 2px solid $primary-color;
        }
    }

    .menu-item {
        font-size: 15px;
        font-weight: 400;
        color: $primary-color;
    }

    .logo-inactive {
        display: none;
    }

    &.inactive {
        background: transparent;
        box-shadow: none;
        .logo-active {
            display: none;
        }
        .logo-inactive {
            display: block;
        }
        .menu-item {
            color: #FFF;
        }
        .request-tutor {
            display: none;
        }
    }
}

@media screen and (min-width: 992px) {
    .header-component {
        height: 87px;
        .phone-txt {
            font-size: 24px;
            color: $yellow-color;
            font-weight: 700;
        }
        .request-tutor {
            width: 210px;
            height: 55px;
            background-color: $red-color;
            border-radius: 10px;
            font-size: 15px;
            color: #FFF;
            font-weight: 700;
            box-shadow: 0px 8px 21px rgba(3, 12, 30, 0.4)
        }
    }
}

@media screen and (max-width: 991px) {
    .header-component {
        height: 75px;
        .mobile-menu {
            display: flex;
            justify-content: space-between;
        }
    }
}
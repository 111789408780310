@import '../../theme.scss';

.schedule-page {
    background: $primary-color;
    color: #FFF;
    width: 100%;
    height: 100vh;
    overflow: scroll;

    .left-col {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        position: relative;
        .schedule-btn {
            width: 100%;
            height: 55px;
            border-radius: 7px;
            background-color: $yellow-color;
            color: #FFF;
            cursor: pointer;
        }
    }
    
    .schedule {
        background: #F6F5F9;
        border-radius: 10px;
        height: 167px;
    }
    .video-container {
        padding: 0 15px;
    }
    .video-item {
        .video-header {
            height: 230px;
            cursor: pointer;
            overflow: hidden;
            border-radius: 10px;
            .avatar {
                width: 100%;
                // height: 100%;
                border-radius: 10px;
            }
        }
        .play-icon-container {
            display: flex;
            margin-top: -145px;
            margin-bottom: 100px;
            cursor: pointer;
            z-index: 10;
            .play-icon {
                width: 60px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .schedule-page {
        margin-top: -87px;
        padding-top: 15px;
        .main-content {
            padding-top: 50px;
        }
        .arrow {
            position: absolute;
            left: -57px;
            top: -88px;
        }
        h1 {
            font-size: 40px;
            line-height: 55px;
            margin-bottom: 15px;
        }
        .video-title {
            font-size: 27px;
        }
        .description {
            font-size: 18px;
            margin-bottom: 30px;
        }
        p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 18px;
        }
        .schedule {
            width: 429px;
        }
        .calendar-content {
            height: 650px;
            overflow: hidden;
        }
        
    }
}

@media screen and (max-width: 991px) {
    .schedule-page {
        margin-top: -70px;
        padding-top: 11px;
        .right-col {
            // padding-bottom: 80px;
        }
        .logo-area {
            display: flex;
            justify-content: center;
        }
        .main-content {
            padding-top: 44px;
        }
        h1 {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 9px;
            text-align: center;
        }
        .video-title {
            font-size: 20px;
            margin-top: 30px;
            text-align: center;
        }
        .description {
            font-size: 15px;
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 22px;
        }
        .schedule {
            width: 450px;
            margin-left: auto;
            margin-right: auto;
        }
        .calendar-content {
            // height: 300px;
            overflow: hidden;
        }
    }
}

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}
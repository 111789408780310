@import '../../../theme.scss';


.whyus-component {
    color: $black-color;
    h1 {
        font-weight: 800;
    }
    p {
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
    }
    .see-more {
        font-size: 15px;
        font-weight: 700;
        color: $yellow-color;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
    }
    .category-item {
        justify-content: space-between;
        display: flex;
        border-radius: 5px;
        margin-bottom: 20px;
        cursor: pointer;

        .num-container {
            border-radius: 50%;
            background-color: rgba(58, 171, 108, 0.1);
            color: $green-color;
        }
    }
    .category-active {
        background-color: $green-color;
        color: #FFF;            
        .num-container {
            background-color: #FFF;
            font-weight: 700;
        }
        .item-description {
            font-weight: 700;
        }
    }
    .logo {
        width: 100%;
    }
    
}

@media screen and (min-width: 992px) {
    .whyus-component {
        padding: 100px 0px;
        h1 {
            font-size: 40px;
            margin-bottom: 40px;
        }
        .category-item {
            height: 80px;
            padding-left: 15px;

            .num-container {
                width: 60px;
                height: 60px;
                font-size: 24px;
            }
            .item-description {
                font-size: 18px;
            }
        }
        .right-area {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

@media screen and (max-width: 991px) {
    .whyus-component {
        padding: 70px 0px;
                
        h1 {
            font-size: 34px;
            margin-bottom: 30px;
        }

        .category-item {
            height: 55px;
            padding-left: 10px;
            padding-right: 10px;
            
            align-items: center;
            .num-container {
                width: 35px;
                height: 35px;
                font-size: 18px;                
            }
            .item-description {
                font-size: 15px;
            }
        }
        .detail-active {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .detail-inActive {
            display: none;
        }
        
    }
}
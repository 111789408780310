@import '../../theme.scss';

.video-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;

            .video-content {
                display: flex;
                margin: auto;
                position: relative;
                width: 100%;
                // background-color: #FFF;

                .muse-video-player {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: black;
                }
                .close-btn {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    // margin-bottom: -18px;
                    z-index: 10;
                }
                .logo-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
        position: relative;
        height: 100%;
    }
    .video-content {
        max-width: 1120px;
        height: 600px;
        padding: 30px;
        border-radius: 10px;
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        position: absolute;
        bottom: 0;
    }
    .video-content {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        width: 100%;
        max-height: calc(100vh - 50px);
        overflow-y: scroll;
        padding: 30px 15px;
    }
}
h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4 {
    font-weight: 900;
}

p {
    font-size: 16px;
}

input, textarea {
    box-shadow: none;
    outline: none;
}

.v-r {
    display: flex;
    flex-direction: column;
}

.v-c {
    display: flex;
    align-items: center;
}

.h-c {
    display: flex;
    justify-content: center;
}

.shadow-object {
    box-shadow: 0px 8px 21px rgba(3, 12, 30, 0.09);
}

.svg-icon {
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
    }
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}


.center-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    cursor: pointer;
}

.btn-2 {
    font-size: 16px;
    line-height: 24px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-family: inherit;
    font-weight: bold;

    &:focus {
        outline: none;
        border: none;
    }
}

.btn-2-outline {
    background: none;
    border: 1px solid $primary-color;
    color: $primary-color;
    &:focus {
        border: 1px solid $primary-color;
    }
    &:hover {
        color: $primary-color;
    }
}

.primary-btn {
    background: $yellow-color;
    color: white;
}

.circle {
    border-radius: 50%;
}

.round-object {
    border-radius: 10px;
}

.btn-link {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $green-color;
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    &:hover {
        text-decoration: none;
        color: white;
    }
}

.sub-list-enter {
    height: fit-content !important;
    min-height: fit-content !important;
    opacity: 1 !important;
    overflow: visible !important;
    transition: all 200ms ease-out;
}
.sub-list-enter-done {
    height: fit-content !important;
    min-height: fit-content !important;
    opacity: 1 !important;
    overflow: visible !important;
    transition: all 400ms ease-out;
}
.sub-list-exit {
    height: fit-content !important;
    min-height: fit-content !important;
    opacity: 0 !important;
    transition: all 200ms ease-out;
}
.sub-list-exit-done {
    height: 0 !important;
    min-height: 0 !important;
    opacity: 0 !important;
    transition: all 400ms ease-out;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 25px;
    height: 25px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (min-width: 992px) {
    .show-web {
        display: block;
    }

    .show-web-flex {
        display: flex;
    }

    .show-mobile, .show-mobile-flex {
        display: none !important;
    }

    ::-webkit-scrollbar-track {
        background: none;
    }

    ::-webkit-scrollbar-thumb {
        background: #BDBDBD; 
        border-radius: 40px;
    }
    
    ::-webkit-scrollbar {
        height: 10px;
        width: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #BDBDBD; 
    }
}

@media screen and (max-width: 991px) {
    .show-mobile {
        display: block;
    }

    .show-web, .show-web-flex {
        display: none !important;
    }

    .show-mobile-flex {
        display: flex;
    }
}

// @font-face {
//     font-family: 'TTNorms';
//     src: url('./Assets/Font/TT-Norms.ttf') format('truetype');
// }

// @font-face {
//     font-family: 'TTNorms';
//     font-weight: bold;
//     src: url('./Assets/Font/TT-Norms-Bold.ttf') format('truetype');
// }

// @font-face {
//     font-family: 'TTNorms';
//     font-weight: 300;
//     src: url('./Assets/Font/TT-Norms-Light.ttf') format('truetype');
// }

// @font-face {
//     font-family: 'TTNorms';
//     font-weight: 500;
//     src: url('./Assets/Font/TT-Norms-Medium.ttf') format('truetype');
// }
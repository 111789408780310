@import '../../theme.scss';

.dropbox-component {
        width: 100%;
        color: $primary-color;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 50px;
      .dropbox-area {
        width: 100%;
        border-radius: 30px;
        background-color: #F3F3F3;
        padding: 5px 30px;
        .drop-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
        }
        .category-item {
            height: 50px;
            width: 100%;
            display: flex;
            align-items: center;
            color: $green-color;
        }
      }

}

@media screen and (min-width: 992px) {
    .dropbox-component {
        
    }
}

@media screen and (max-width: 991px) {
    .dropbox-component {
        
    }
}
@import '../../theme.scss';


.bottom-modal-component {
    color: $black-color;
    
    z-index: 10;
    .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px;
    } 
    p {
        font-weight: 400;
        line-height: 40px;
        margin-left: 20px;
    }
    .bottom-modal-content {
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px;
        .close-btn {
            cursor: pointer;
            position: absolute;
            right: 30px;
            top: 30px;
        }
    }
    .request-btn {
        background-color: $red-color;
        height: 55px;
        border-radius: 10px;
        font-size: 15px;
        font-weight: bold;
        font-size: 15px;
        color: #FFF;
    }
    .bottom-description {
        font-size: 15px;
        color: $green-color;
        height: 28px;
        padding: 5px;
        background-color: rgba(58, 171, 108, 0.1);
        border-radius: 5px;
    }
    
}

@media screen and (min-width: 992px) {
    .bottom-modal-component {
        position: fixed;
        bottom: 10px;
        right: 10px;

        p {
            font-size: 20px;
        }
        
        .bottom-modal-content {
            
            
        }
        .close-container {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            .close-content {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: rgba(218, 65, 103, 0.1);
                // animation: blink-animation 3s steps(5, start) infinite;
                // -webkit-animation: blink-animation 3s steps(5, start) infinite;
                animation: blink 3s infinite;
                .close-cover-middle {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    
                    background-color: rgba(218, 65, 103, 0.2);
                    .close-cover-center {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: $red-color;
                        color: #FFF;
                        font-size: 10px;
                        opacity: 1 !important;
                    }
                }
                .inactive {
                    background-color: transparent;
                }
            }
            .inactive {
                background-color: transparent;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .bottom-modal-component {
        position: fixed;
        bottom: 140px;
        right: 10px;
        left: 10px;
        p {
            font-size: 15px;
        }
    }
}

@keyframes blink {
    50%{opacity: 0.5;}
    70%{opacity: 0.7;}
    100%{opacity: 1;}
}
@import '../../../theme.scss';


.media-component {
    color: $black-color;
    h1 {
        font-weight: 800;
    }
    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
    }
    .left-area {
        border-radius: 5px;
        background-color: #FFF;
        .logo-content {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: solid 1px $green-color;
            padding: 3px;
        }
    }
    
    .logo-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        .brand-container {
            border-radius: 10px;
            border: solid 7px rgba(251, 169, 46, 0.2);
        }
        .brand-content {
            border-radius: 5px;
            border: solid 1px $gray-color;
            padding: 10px 5px;
            cursor: pointer;
        }
    }
    
    .brand {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
   
}

@media screen and (min-width: 992px) {
    .media-component {
        padding: 100px 0px;
        h1 {
            font-size: 40px;
            margin-bottom: 40px;
        }       
        .logo-list {
            max-width: 960px;
        }
        .brand-content {
            width: 170px;
            height: 80px;
        }
        .main-row {
            height: 300px;
            background: url('../../../Assets/Home/media_bg.png') no-repeat;
            background-position: center bottom;
            background-size: 100% 100%;
            .col-lg-6 {
                padding-right: 0;
                padding-left: 0;
            }
            .left-container {
                display: flex;
                justify-content: flex-end;
                .left-area {
                    width: 80%;
                    height: 204px;
                    padding: 20px 20px;
                    margin-bottom: 35px;
                }
            }
            .right-container {
                display: flex;
                .right-area {
                    width: 80%;
                    height: 264px;
                    background-color: $green-color;
                    border-radius: 7px;
                    .video {
                        width: 100%;
                        height: 100%;
                        border-radius: 7px;
                    }
                    .play-icon {
                        cursor: pointer;
                        position: absolute;
                        z-index: 2;
                        left: calc(50% - 84px);
                        top: calc(50% - 52px);
                        width: 60px;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 991px) {
    .media-component {
        padding: 70px 0px;
                
        h1 {
            font-size: 34px;
            margin-bottom: 10px;
            text-align: center;
        }

        .brand-content {
            width: 76px;
            height: 55px;
        }
        .main-row {
            .left-container {
                display: flex;
                justify-content: flex-end;
                .left-area {
                    width: 100%;
                    height: 220px;
                    padding: 10px 10px;
                    margin-bottom: -35px;
                }
            }
            .right-container {
                display: flex;
                .right-area {
                    width: 90%;
                    margin-left: 15px;
                    height: 160px;
                    background-color: $green-color;
                    border-radius: 7px;
                    .video {
                        width: 100%;
                        height: 100%;
                        border-radius: 7px;
                    }
                    .play-icon {
                        position: absolute;
                        z-index: 10;
                        left: calc(50% - 43px);
                        top: calc(50% - 29px);
                        width: 60px;
                    }
                }
    
            }
        }
    }
}
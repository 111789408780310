@import '~bootstrap/scss/bootstrap.scss';
@import './theme.scss';
@import './custom.scss';

body {
  margin: 0;
  font-family: TTNorms;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-color;

  &.modal-open {
    overflow: hidden;
  }
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;

  #main {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    .content {
      display: flex;
      flex-direction: column;
    }

    &.blur {
      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        // background-color: rgba(black, 0.45);
        z-index: 15;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    padding-top: 87px;
  }
}

@media screen and (max-width: 991px) {
  .content {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.dot-bg {
  width: 100%;
  height: 63px;
  margin-left: 13px;
  margin-top: -52px;
  z-index: 0 !important;
  position: relative;
}

.bg-dot {
  position: absolute;
  width: calc(100% + 6px);
  height: 100%;
  left: 6px;
  bottom: -12px;
  z-index: 0;
  background: url('./Assets/Home/bg-dot.png');
}

.small-dot {
  height: 63px;
}

.span-dot {
  width: 100%;
  left: 14px;
}

.row-dot {
  width: calc(100% + 24px);
}

.shadow-object {
  box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
}

.overlay-loader {
  z-index: 1010;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000050;
}